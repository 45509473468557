import React from 'react'
import { Link } from "gatsby"
import img_logo_small from '../images/eding_logo_small.svg'
import img_map from '../images/maps_img.png'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { StickyContainer, Sticky } from 'react-sticky';

class Contact extends React.Component{
    render(){
        const data = this.props.data.allWordpressPage.edges[0].node.acf;
        return(
            <Layout>
            <SEO title="Kontaktirajte nas" keywords={[`telekomunikacije`, `inžinjering`]} />
            <div id="_contact">
                <section className="section page-title">
                    <div className="container">
                        <h1 className="title">Kontaktirajte nas</h1>
                    </div>
                </section>

                <section className="section page-content">
                <div className="bottom-background"></div>
                    <div className="container">
                        <div className="columns informations">
                            <div className="column first">
                                <div className="inner">
                                    <h6 className="info-column-title">{data.naziv_pogona_1}</h6>
                                    <p className="info-column-line">{data.adresa_pogona_1}</p>
                                    <a href={"tel:"+data.telefon_pogona_1} className="info-column-line link">t: {data.telefon_pogona_1}</a>
                                    <p className="info-column-line">f: {data.fax_pogona_1}</p>
                                    <a className="info-column-line link" href={"mailto:"+data.email_pogona_1}>e: {data.email_pogona_1}</a>
                                </div>     
                            </div>
                            {/*COLUMN END */}

                            <div className="column second">
                                <div className="inner">
                                    <h6 className="info-column-title">{data.naziv_pogona_2}</h6>
                                    <p className="info-column-line">{data.adresa_pogona_2}</p>
                                    <a href={"tel:"+data.telefon_pogona_2} className="info-column-line link">t: {data.telefon_pogona_2}</a>
                                    <p className="info-column-line">f: {data.fax_pogona_2}</p>
                                    <a className="info-column-line link" href={"mailto:"+data.email_pogona_2}>e: {data.email_pogona_1}</a>
                                </div>
                            </div>
                            {/*COLUMN END */}

                            <div className="column third">
                                <div className="inner">
                                    <h6 className="info-column-title">{data.naziv_pogona_3}</h6>
                                    <p className="info-column-line">{data.adresa_pogona_3}</p>
                                    <a href={"tel:"+data.telefon_pogona_3} className="info-column-line link">t: {data.telefon_pogona_3}</a>
                                    <p className="info-column-line">f: {data.fax_pogona_3}</p>
                                    <a className="info-column-line link" href={"mailto:"+data.email_pogona_3}>e: {data.email_pogona_3}</a>
                                </div>
                            </div>
                            {/*COLUMN END */}
                        </div>
                        {/*INFORMATIONS END */}
                       
                        <form method="post" name="contact" className="contact-form columns is-multiline" method="POST" action="/hvala-vam" data-netlify="true" data-netlify-honeypot="bot-field">
                            <input type="hidden" name="form-name" value="contact" />
                            <label for="name" className="column is-4">
                                <span>Ime i prezime *</span>
                                <input type="text" name="name" placeholder="JOHN SMITH" required/>
                            </label>

                            <label for="email" className="column is-4">
                                <span>Email *</span>
                                <input type="text" name="email" placeholder="NAME@EXAMPLE.COM" required />
                            </label>

                            <label for="phone" className="column is-4">
                                <span>Telefon *</span>
                                <input type="text" name="phone" placeholder="NPR. 061 123 456" required/>
                            </label>


                            <label for="message" className="column is-12">
                                <span>Poruka *</span>
                                <textarea name="message" placeholder="SADRŽAJ VAŠE PORUKE" required></textarea>
                            </label>
                            
                            <div className="column is-12"><button type="submit" className="button _btnHeader _btnContact"><span>POŠALJI PORUKU</span></button></div>
                        </form>
                    </div>
                </section>
                <section id="_locations" className="section">
                                <div className="container">
                                    <div className="columns is-gapless">
                                        <div className="column is-7">
                                            <div className="__innerContainer">
                                                <div className="_inner">
                                                    <span className="_subtitle">Lokacija</span>
                                                    <h1 className="_title">Imamo predstavništva i u drugim zemljama.</h1>
                                                        <div className="_boxes">
                                                        {data.lokacija_1_naziv !== "" && (
                                                        <div className="_box">
                                                            <div className="title">
                                                                <span className="image">
                                                                    <img src={img_logo_small} alt="EDING Telecom"/>
                                                                </span>
                                                                <span dangerouslySetInnerHTML={{__html: data.lokacija_1_naziv }}></span>
                                                            </div>
                                                            <div className="content" dangerouslySetInnerHTML={{__html: data.lokacija_1_adresa }}/>
                                                            {data.lokacija_1_telefon !== "" && (<p className="content">                                                      
                                                                t: <a href={"tel:"+data.lokacija_1_telefon}>{data.lokacija_1_telefon}</a>
                                                            </p>
                                                            )}
                                                            {data.lokacija_1_fax !== "" && (<p className="content">                                                      
                                                                f: {data.lokacija_1_fax}
                                                            </p>
                                                            )}
                                                        </div>
                                                    )}
                                                    {data.lokacija_2_naziv !== "" && (
                                                        <div className="_box">
                                                            <div className="title">
                                                                <span className="image">
                                                                    <img src={img_logo_small} alt="EDING Telecom"/>
                                                                </span>
                                                                <span dangerouslySetInnerHTML={{__html: data.lokacija_2_naziv }}></span>
                                                            </div>
                                                            <div className="content" dangerouslySetInnerHTML={{__html: data.lokacija_2_adresa }}/>
                                                            {data.lokacija_2_telefon !== "" && (<p className="content">                                                      
                                                                t: <a href={"tel:"+data.lokacija_2_telefon}>{data.lokacija_2_telefon}</a>
                                                            </p>
                                                            )}
                                                            {data.lokacija_2_fax !== "" && (<p className="content">                                                      
                                                                f: {data.lokacija_2_fax}
                                                            </p>
                                                            )}
                                                        </div>
                                                    )}
                                                    {data.lokacija_3_naziv !== "" && (
                                                        <div className="_box">
                                                            <div className="title">
                                                                <span className="image">
                                                                    <img src={img_logo_small} alt="EDING Telecom"/>
                                                                </span>
                                                                <span dangerouslySetInnerHTML={{__html: data.lokacija_3_naziv }}></span>
                                                            </div>
                                                            <div className="content" dangerouslySetInnerHTML={{__html: data.lokacija_3_adresa }}/>
                                                            {data.lokacija_3_telefon !== "" && (<p className="content">                                                      
                                                                t: <a href={"tel:"+data.lokacija_3_telefon}>{data.lokacija_3_telefon}</a>
                                                            </p>
                                                            )}
                                                            {data.lokacija_3_fax !== "" && (<p className="content">                                                      
                                                                f: {data.lokacija_3_fax}
                                                            </p>
                                                            )}
                                                        </div>
                                                    )}
                                                    {data.lokacija_4_naziv !== "" && (
                                                        <div className="_box">
                                                            <div className="title">
                                                                <span className="image">
                                                                    <img src={img_logo_small} alt="EDING Telecom"/>
                                                                </span>
                                                                <span dangerouslySetInnerHTML={{__html: data.lokacija_4_naziv }}></span>
                                                            </div>
                                                            <div className="content" dangerouslySetInnerHTML={{__html: data.lokacija_4_adresa }}/>
                                                            {data.lokacija_4_telefon !== "" && (<p className="content">                                                      
                                                                t: <a href={"tel:"+data.lokacija_4_telefon}>{data.lokacija_4_telefon}</a>
                                                            </p>
                                                            )}
                                                            {data.lokacija_4_fax !== "" && (<p className="content">                                                      
                                                                f: {data.lokacija_4_fax}
                                                            </p>
                                                            )}
                                                        </div>
                                                    )}
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column is-5 image-column">   
                                            <div className="_mapImage">
                                                <img src={img_map} alt="EDING Telecom - Our locations"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </section>
            </div>
            </Layout>
        );
    }
}

export default Contact

export const pageQuery = graphql`
    query postsQueryKontakt{
        allWordpressPage(filter: {slug: {eq: "postavke-stranice"}}) {
            edges {
            node {
                id
                acf {
                naziv_pogona_1
                adresa_pogona_1
                telefon_pogona_1
                fax_pogona_1
                email_pogona_1
                naziv_pogona_2
                adresa_pogona_2
                telefon_pogona_2
                fax_pogona_2
                email_pogona_2
                naziv_pogona_3
                adresa_pogona_3
                email_pogona_3
                telefon_pogona_3
                fax_pogona_3
                lokacija_1_naziv
                lokacija_2_naziv
                lokacija_3_naziv
                lokacija_4_naziv
                lokacija_1_adresa
                lokacija_2_adresa
                lokacija_3_adresa
                lokacija_4_adresa
                lokacija_1_telefon
                lokacija_2_telefon
                lokacija_3_telefon
                lokacija_4_telefon
                lokacija_1_fax
                lokacija_2_fax
                lokacija_3_fax
                lokacija_4_fax
                }
            }
            }
        }
    }
`